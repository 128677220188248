import * as React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SvgIcon from '@mui/material/SvgIcon';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { UseTempDrawerHook } from './TempDrawerHook';
import { Routes } from '../config/AppConfig'


// This method breaks the routes, into a list of menu lists.  The UI writes a divider between each list.
const getMenuLists = (routes) => {
   if (!!!routes) {
      console.error("Missing 'routes' property")
      return []
   }

   let menuLists = []

   let fullList = routes.filter(route => {
      return !!route.menuLabel
   })

   let menuList = []
   fullList.forEach(route => {
      if (route.menuLabel === 'DIV') {
         menuLists.push(menuList)
         menuList = []
      } else {
         menuList.push(route)
      }
   });

   if (menuList.length > 0) {
      menuLists.push(menuList)
   }

   return menuLists
};


export default function TempDrawer() {
   const [tempDrawOpen, setTempDrawerOpen] = UseTempDrawerHook()
   const [menuLists, setMenuLists] = React.useState([])
   const [selectedItem, setSelectedItem] = React.useState('');


   React.useEffect(() => {
      setMenuLists(getMenuLists(Routes))
   },
      // eslint-disable-next-line
      []);

   const toggleDrawer = (open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
         return;
      }

      setTempDrawerOpen(open);
   };

   const handleListItemClick = (key, navTitle) => (event) => {
      setSelectedItem(key);
   };

   return (
      <SwipeableDrawer anchor='left' open={tempDrawOpen} onOpen={toggleDrawer(true)} onClose={toggleDrawer(false)}>
         <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
         >
            {menuLists.map((menuList, index) => (
               <div key={index}>
                  <List>
                     {menuList.map((route) => (
                        <ListItem
                           button
                           selected={selectedItem === route.menuLabel}
                           onClick={handleListItemClick(route.menuLabel, route.navTitle)}
                           key={route.menuLabel}
                           component={Link}
                           to={route.path}
                        >
                           <ListItemIcon>
                              {!!route.menuIcon && <SvgIcon component={route.menuIcon} />}
                           </ListItemIcon>
                           <ListItemText primary={route.menuLabel} />
                        </ListItem>
                     ))}
                  </List>
                  {index + 1 < menuLists.length && <Divider />}
               </div>
            ))}

         </Box>
      </SwipeableDrawer>
   )

}