import React from 'react'

let listeners = [];

let title = ''

const setNavBarInfo = (newTitle) => {
    title = newTitle
    listeners.forEach((listener) => {
        listener(title);
    })
};

export function UseTopAppBarHook() {
    const newListener = React.useState()[1]

    React.useEffect(() => {
        listeners.push(newListener)

        return () => {
            listeners = listeners.filter(listener => listener !== newListener);
        };
    }, 
    // eslint-disable-next-line
    [])


    return [ title, setNavBarInfo ]
}
