import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

import { useMediaQuery } from '@mui/material';

import { UseTempDrawerHook } from './TempDrawerHook';
import { TopNav } from '../config/AppConfig';
import { UseTopAppBarHook } from './TopAppBarHook';

const Search = styled('div')(({ theme }) => ({
   position: 'relative',
   borderRadius: theme.shape.borderRadius,
   backgroundColor: alpha(theme.palette.common.white, 0.15),
   '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
   },
   marginLeft: 0,
   width: '100%',
   [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
   },
}));

const Windmill = styled('img')(({ theme }) => ({
   width: '50px',
   display: 'inline-block',
   alignItems: 'right',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
   padding: theme.spacing(0, 2),
   height: '100%',
   position: 'absolute',
   pointerEvents: 'none',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
   color: 'inherit',
   '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
         width: '12ch',
         '&:focus': {
            width: '20ch',
         },
      },
   },
}));

export default function TopAppBar() {
   const setTempDrawerOpen = UseTempDrawerHook()[1]
   const [navBarTitle] = UseTopAppBarHook()
   const wideScreen = useMediaQuery('(min-width: 600px)')

   const openDrawer = () => {
      setTempDrawerOpen(true)
   }

   return (
      <Box sx={{ flexGrow: 1 }}>
         <AppBar position="static" color='primary'>
            <Toolbar>
               <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  sx={{ mr: 2 }}
                  onClick={openDrawer}
               >
                  <MenuIcon />
               </IconButton>
               <Typography
                  variant={wideScreen ? "h4" : "h5"}
                  noWrap
                  component="div"
                  sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}
               >
                  {!!navBarTitle ? TopNav.leaderText + navBarTitle : TopNav.defaultTitle}
               </Typography>
               {!!TopNav.searchBar &&
                  < Search >
                     <SearchIconWrapper>
                        <SearchIcon />
                     </SearchIconWrapper>
                     <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                     />
                  </Search>
               }
               <Windmill src="images/qtrmill.png" alt='windmill' sx={{display: { xs: 'block', sm: 'block' } }}/>
            </Toolbar>
         </AppBar>
      </Box >
   );
}