import { fetchGet, fetchPost } from "./fetchcalls"

export async function GetEmailCount() {
   return await fetchGet(`/api/email/usage`)
}

export async function GetRouteCount() {
   return await fetchGet(`/api/usage/route`)
}

export async function GetIPCount() {
   return await fetchGet(`/api/usage/ip`)
}

export async function GetIPDateCount() {
   return await fetchGet(`/api/usage/ip/date`)
}

export async function SaveRouteHistory(route) {
   return await fetchPost(`/api/usage/route`, {Route: route})
}
