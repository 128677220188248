import React from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

let listeners = [];

let gState = {
    open: false,
    severity: 'info',
    message: '',
}

const setNotificationState = (state) => {
    gState = { ...state }

    listeners.forEach((listener) => {
        listener(gState);
    })
};

function useNotificationHook() {
    const newListener = React.useState()[1]

    React.useEffect(() => {
        listeners.push(newListener)

        return () => {
            listeners = listeners.filter(listener => listener !== newListener);
        };
    },
        // eslint-disable-next-line
    [])


    return [gState, setNotificationState]
}


export function Notify(severity, message) {
    setNotificationState({open: true, severity, message})
}

function NotificationWidget(props) {
    const {  ...other } = props;

    const [state, setState] = useNotificationHook({open: false})

    const handleNotifClose = (event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
        
            setState({...state, open: false});
    }
  
    return (
        <Snackbar open={state.open} autoHideDuration={5000} onClose={handleNotifClose} 
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            {...other}
        >
            <MuiAlert elevation={6} variant="filled" onClose={handleNotifClose} severity={state.severity} >
                {state.message}
            </MuiAlert>            
        </Snackbar>
    );
  }

export default NotificationWidget;
