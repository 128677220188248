import * as React from 'react';

let listeners = [];

let layout = {
   tempDrawOpen: false,
}

const setTempDrawerOpen = (open) => {
   layout.tempDrawOpen = open
   listeners.forEach((listener) => {
       listener(layout.tempDrawOpen);
   })
};


export function UseTempDrawerHook(open) {
   // layout.tempDrawOpen = !!open ? true : false
   const newListener = React.useState()[1]

   React.useEffect(() => {
      listeners.push(newListener)

      return () => {
         // Called just before the component unmount
         listeners = listeners.filter(listener => listener !== newListener);
      };
   },
      // eslint-disable-next-line
      [])


   return [layout.tempDrawOpen, setTempDrawerOpen]
}