import DashboardIcon from '@mui/icons-material/Dashboard';
import InfoIcon from '@mui/icons-material/Info';

import About from '../routes/About/About';
import Home from '../routes/Home/Home';
import NotFound from '../routes/NotFound/NotFound'
import Usage from '../routes/Usage/Usage';

export const TopNav = {
   leaderText: '',
   defaultTitle: 'QuarterMill Software',
   searchBar: false,
}

export const Routes = [
   {
     menuLabel: "QuarterMill Software",
     path: "/",
     navTitle: "QuarterMill Software",
     component: Home,
     exact: true,
     menuIcon: DashboardIcon,
   },
   // {
   //   menuLabel: "Our Approach",
   //   path: "/approach",
   //   navTitle: "Our Approach",
   //   component: Approach,
   //   menuIcon: HikingIcon,
   // },
   // {
   //   menuLabel: "Solutions",
   //   path: "/solutions",
   //   navTitle: "Solutions",
   //   component: Solutions,
   //   menuIcon: EmojiObjectsIcon,
   // },
   {
     menuLabel: "DIV",
   },
   {
     menuLabel: "About Us",
     path: "/about",
     navTitle: "About Us",
     component: About,
      menuIcon: InfoIcon,
   },
   {
      path: "/usage",
      component: Usage,
    },
   {
     navTitle: "Not Found (404)",
     path: "*",
     component: NotFound,
   },
 ]