import * as React from 'react';

import {
   Switch,
   Route,
   useHistory,
   Redirect
} from "react-router-dom";

import { UseTopAppBarHook } from './TopAppBarHook';
import { Routes } from '../config/AppConfig'
import { SaveRouteHistory } from '../api/usage';

let lastpath = "";

export default function SwitchRoute() {
   const setTopBarTitle = UseTopAppBarHook()[1]

   let history = useHistory()

   React.useEffect(() => {
      trackPageView(); 
      history.listen(trackPageView); // Track all subsequent pageviews
   }, [history]);
  
   function trackPageView() {
      SaveRouteHistory(window.location.pathname.toString())
   }

   const isValid = (route) => {
      return !!route.component
   }

   React.useEffect(() => {
      const listener = history.listen(handleHistory)
      handleHistory(history.location)

      return () => {
         listener.unlisten()
      };
   },
      // eslint-disable-next-line
      [])

   const handleHistory = (location) => {
      if (lastpath !== location.pathname) {
         lastpath = location.pathname
         let route = Routes.find((route) => {
            if (location.pathname === '/') {
               return location.pathname === route.path
            }
            else {
               if (route.path === '/') {
                  return false
               }
               return location.pathname.indexOf(route.path) === 0
            }
         })
         if (!!route && !!route.navTitle) {
            setTopBarTitle(route.navTitle)
         }
      }
   }

   return (
      <Switch>
         {Routes.filter((route) => { return route.menuLabel !== 'DIV' }).map((route) => (
            <Route
               exact={route.exact}
               key={route.path}
               path={route.path}
               render={(props) =>
                  isValid(route) ?
                     (
                        <route.component {...props} />
                     ) :
                     (
                        <Redirect to={'/404'} />
                     )}
            />
         ))}
      </Switch>
   );
}