import * as React from 'react';

import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';

import { Typography, useMediaQuery } from '@mui/material';

import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import { SendEmail } from '../../api/email'
import { Notify } from '../../components/NotificationWidget';

import Footer from '../../components/Footer'


const Image = styled('img')(({ theme }) => ({
   width: "80px",
   marginRight: "5px",
   float: "center",
}));

const Header = styled(Typography)(({ theme }) => ({
   color: 'green',
}));

const HeaderPad = styled(Typography)(({ theme }) => ({
   color: 'green',
   margin: '.5em'
}));

export default function Home() {
   const wideScreen = useMediaQuery('(min-width: 600px)')

   // let classes=appStyles()

   const [formName, setFormName] = React.useState('');
   const [formEmail, setFormEmail] = React.useState('');
   const [formOrganization, setFormOrg] = React.useState('');
   const [formPhone, setFormPhone] = React.useState('');
   const [formMessage, setFormMessage] = React.useState('');

   const handleSubmit = async () => {
      let content = {
         name: formName,
         organization: formOrganization,
         email: formEmail,
         phone: formPhone,
         message: formMessage,

      };
      try {
         await SendEmail(content)
         clearForm()
         Notify('success', 'Successfully sent form.')
      } catch (err) {
         Notify('error', `Failed to send form`)
         console.error(err)
      }
   }

   const handleNameChange = (event) => {
      setFormName(event.target.value);
   };

   const handleOrgChange = (event) => {
      setFormOrg(event.target.value);
   };

   const handleEmailChange = (event) => {
      setFormEmail(event.target.value);
   };

   const handlePhoneChange = (event) => {
      setFormPhone(event.target.value);
   };

   const handleMessageChange = (event) => {
      setFormMessage(event.target.value);
   };

   const clearForm = () => {
      setFormName('')
      setFormOrg('')
      setFormEmail('')
      setFormPhone('');
      setFormMessage('');
   };

   return (
      <Box>
         <Box>
            <img src='./images/windmill1250-140.jpg' alt="" width="100%" />
         </Box>
         <Box style={{ padding: '25px' }}>
            <Header variant={wideScreen ? "h2" : "h5"} align="center">
               Building Solutions with Modern Development  
            </Header>
         </Box>
         <Box style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }} >
            <Card sx={{ maxWidth: 350, marginTop: '12px' }} >
               <CardMedia
                  component="img"
                  height="200"
                  image="images/softwaredesign_nofaces.jpg"
                  alt=""
               />
               <CardContent>
                  <Typography gutterBottom variant="h6" component="div" style={{ color: "black" }} align="center">
                     Modern Development
                  </Typography>
                  <Typography variant="body2" style={{ textAlign: "justify" }}>
                     We build complete software products. We can handle single or multi-tenant applications, with a cybersecurity awareness that you 
                     should expect in modern software. First, we study and learn your vision for the product.
                     Then, we express that vision using modern development practices.  This includes
                     a DevSecOps pipeline, current tooling and language selection, and a fully transparent Agile
                     delivery model.
                  </Typography>
               </CardContent>
            </Card>
            <Card sx={{ maxWidth: 350, marginTop: '12px'  }} align="full">
               <CardMedia
                  component="img"
                  height="200"
                  image="images/consulting_clearnofaces.jpg"
                  alt=""
               />
               <CardContent>
                  <Typography gutterBottom variant="h6" component="div" style={{ color: "black" }} align="center">
                     We are Partners
                  </Typography>
                  <Typography variant="body2" style={{ textAlign: "justify" }}>
                     QuarterMill staff can partner with your team to achieve your product development objectives.  We provide experienced developers
                     who integrate, advise, assist, and mentor.  We aim to meet you where your need is.  We are capable with both instructional models, and with
                     show by example models.  Our goal is to help you achieve your deliverables.
                  </Typography>
               </CardContent>
            </Card>
            <Card sx={{ maxWidth: 350, marginTop: '12px'  }} align="full">
               <CardMedia
                  component="img"
                  height="200"
                  image="images/training_nobodies.jpg"
                  alt=""
               />
               <CardContent>
                  <Typography gutterBottom variant="h6" component="div" style={{ color: "black" }} align="center">
                     Customized Learning
                  </Typography>
                  <Typography variant="body2" style={{textAlign: "justify" }}>
                     We offer unique training programs tuned to meet your specific needs. As a simple example, we can put together classroom-style
                     offerings on specific technologies.  Or, as a more complex example, we can work with you to develop or improve upon 
                     an immersive Product Dojo.  We can adjust the training to meet you where your needs are.
                  </Typography>
               </CardContent>
            </Card>
            <Card sx={{ maxWidth: 350, marginTop: '12px'  }} align="full">
               <CardMedia
                  component="img"
                  height="200"
                  image="images/apprenticeship_oneheadallignment.jpg"
                  alt=""
               />
               <CardContent>
                  <Typography gutterBottom variant="h6" component="div" style={{color: "black"}} align="center">
                     Apprenticeship Program
                  </Typography>
                  <Typography variant="body2" style={{textAlign: "justify" }}>
                     Have you ever heard anyone say we can't find technical talent in the US?  Did you know that many large companies 
                     justify hiring foreign labor by saying they can't find talent here in the US?  This
                     is a difficult problem.  We are trying to help by keeping a live apprenticeship program in place.  We 
                     aim to train interested people in modern software practices by immersing them in real work.
                  </Typography>
               </CardContent>
            </Card>
         </Box>
         <Box style={{ margin: '20px 10px', padding: '10px 0px 0px 5px', backgroundColor: '#e0e0e0', borderRadius: '20px', marginTop: '20px' }} >
            <Typography variant="h5" style={{ color: "green", textAlign: 'center' }}>Preferred Technologies</Typography>
            <Box style={{ alignItems: "center", display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }} >
               <Image src="images/react.png" alt="" style={{ margin: '25px' }} />
               <Image src="images/gopher.png" alt="" />
               <Image src="images/angular.svg" alt="" />
               <Image src="images/javascript.svg" alt="" />
               <Image src="images/typescript.svg" alt="" />
               <Image src="images/html5.png" style={{ padding: "5px 0 0 0" }} alt="" />
               <Image src="images/mongodb.png" alt="" />
               <Image src="images/postgresql.svg" alt="" />
               <Image src="images/nodejs.svg" alt="" />
               <Image src="images/java.svg" alt="" />
            </Box>
         </Box>
         <Box  id='contact-form'>
            <HeaderPad variant="h5" align="center">
               Let's get in touch and discuss your tech needs!
            </HeaderPad>
            <HeaderPad variant="h6" align="center">
               Contact our team at aaron@quartermillsoftware.com, call 314.397.5714, or fill out our form below.
            </HeaderPad>
            <HeaderPad variant="h6" align="center">
               We look forward to speaking with you!
            </HeaderPad>
         </Box>
         <Box
            component="form"
            sx={{
               // border: '1px solid black', 
               m: 1,
               width: '32ch',
               margin: 'auto',
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               border: '1px solid lightgrey',
               borderRadius: '20px',
            }}
            autoComplete="off"
         >
            <TextField required error={!!!formName} sx={{width: '27ch', marginTop: '20px'}} id="Name" label="Name" value={formName} variant="filled" onChange={handleNameChange} />
            <TextField sx={{width: '27ch', marginTop: '20px'}} id="Organization" label="Organization" value={formOrganization} variant="filled" onChange={handleOrgChange} />
            <TextField required error={!!!formEmail} sx={{width: '27ch', marginTop: '20px'}} id="Email" label="Email" value={formEmail} variant="filled" onChange={handleEmailChange} />
            <TextField sx={{width: '27ch', marginTop: '20px'}} id="Phone" label="Phone" value={formPhone} variant="filled" onChange={handlePhoneChange} />
            <TextField
               required
               error={!!!formMessage}
               sx={{width: '27ch', marginTop: '20px'}} 
               id="Message"
               label="How can we help?"
               multiline
               rows={4}
               value={formMessage}
               variant="filled"
               onChange={handleMessageChange}
            />
            <Box style={{ padding: '30px' }}>
               <Stack direction="row" spacing={2}>
                  <Button disabled={ !!!formName || !!!formEmail || !!!formMessage } onClick={handleSubmit} variant="contained" endIcon={<SendIcon />}>
                     Send
                  </Button>
               </Stack>
            </Box>
         </Box>
        <Footer />
      </Box>
   )
}

