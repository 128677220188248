
const baseUrl = process.env.REACT_APP_API_URL

export async function fetchPost(url, payload) {
    let opts = {
        method: 'POST'
    }
    return fetchWithPayload(url, opts, payload)
}

async function fetchWithPayload(url, opts, payload) {
    opts["headers"] = {
        'Content-Type': 'application/json',
    }

    if (!!payload) {
        opts["body"] = JSON.stringify(payload)
    }

    return execute(url, opts)
}

async function execute(url , opts) {
    const response = await fetch(baseUrl + url, opts)

    if (response.status >= 200 && response.status <= 299) {
        return response.json()
    }

    let errorText = await response.text()

    throw Error(errorText)
}

export async function fetchGet(url) {
   let opts = {
       method: 'GET',
   }    

   return execute(url, opts)
}