import * as React from 'react';

import {
   BrowserRouter as Router,
 } from "react-router-dom";

import {ThemeProvider } from '@mui/material/styles';

import TopAppBar from './layout/TopAppBar';
import TempDrawer from './layout/TempDrawer';
import { Theme } from './config/Theme';
import SwitchRoute from './layout/SwitchRoute'
import NotificationWidget from './components/NotificationWidget';

function App() {
	return (
		<ThemeProvider theme={Theme}>
         <Router>
            <TopAppBar/>
            <TempDrawer/>
            <SwitchRoute />
         </Router>
        <NotificationWidget />
		</ThemeProvider>
	);
}

export default App;
