import * as React from 'react';

import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';


import { Typography } from '@mui/material';

import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';

import Footer from '../../components/Footer'


const Header = styled( Typography )(({ theme }) => ({
   color: 'green',
   
}));

export default function About() {
   return (
      <Box>
         <img src='./images/archTrees1250-140.jpg' alt="" width="100%" />
         
         <Box>
            <Box style={{padding:'25px'}}>
               <Header variant="h2" align="center">
               Our Team  
               </Header>
            </Box>
            <Box style={{display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }} >
               <Card sx={{ maxWidth: 350, marginTop: '12px' }} >
                  <CardMedia
                  component="img"
                  height="200"
                  image="/images/aaronGreece_crop.jpg"
                  alt=""
                  />
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div" align="center">
                        Aaron Cox
                     </Typography>
                     <Typography gutterBottom variant="h6" component="div" align="center" style={{color: "gray"}}>
                        Technical Product Owner
                     </Typography>
                     <Typography variant="body2" style={{ textAlign: "justify" }}>
                        After working in software development in the St. Louis area for quite a long time, Aaron founded Quartermill Software in 2016.  
                        He specializes in software development and integration.  However, Aaron is convinced that a large gap in technical product 
                        management is prevalent in firms that write software.  He is filling that gap by actively developing 
                        software with a specific focus on, and inclusion of product management.  His interests outside of the office include equity trading 
                        algorithms, international travel, and the occasional fine cigar.
                     </Typography>
                  </CardContent>
               </Card>
               <Card sx={{ maxWidth: 350, marginTop: '12px'  }}>
                  <CardMedia
                  component="img"
                  height="200"
                  image="/images/deniseGreece2_crop.jpg"
                  alt=""
                  />
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div" align="center">
                        Denise Cox
                     </Typography>
                     <Typography gutterBottom variant="h6" component="div" align="center" style={{color: "gray"}}>
                        Business Development
                     </Typography>
                     <Typography variant="body2" style={{ textAlign: "justify" }}>
                        After teaching for 21 years in both private and public school settings, Denise retired in 2020 from a 
                        North St. Louis City charter school and joined QuarterMill Software.  She specializes in research, curriculum design, and the development of our business and apprenticeship programs. She's 
                        also learning to code! Outside of the office, her interests include volunteering for the IISTL, book clubs, 
                        international travel, gardening, and visiting Missouri wineries.
                     </Typography>
                  </CardContent>
               </Card>
               <Card sx={{ maxWidth: 350, marginTop: '12px'  }}>
                  <CardMedia
                  component="img"
                  height="200"
                  image="/images/erin344-228.jpg"
                  alt=""
                  />
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div" align="center">
                        Erin Thornburg
                     </Typography>
                     <Typography gutterBottom variant="h6" component="div" align="center" style={{color: "gray"}}>
                        Lead Developer
                     </Typography>
                     <Typography variant="body2" style={{ textAlign: "justify" }}>
                        Erin has been developing software for 5 years. She enjoys working with multiple languages and platforms and loves learning new things. 
                        Her recent tech interests include Kotlin, Golang, and AWS for deployments. Her interests outside of work include exploring nature, animals, finance and accounting, and cooking.
                     </Typography>
                  </CardContent>
               </Card>
               <Card sx={{ maxWidth: 350, marginTop: '12px'  }} >
                  <CardMedia
                  component="img"
                  height="200"
                  image="/images/glenn348-228.jpg"
                  alt=""
                  />
                  <CardContent>
                     <Typography gutterBottom variant="h5" component="div" align="center">
                        Glenn Thornburg
                     </Typography>
                     <Typography gutterBottom variant="h6" component="div" align="center" style={{color: "gray"}}>
                        Developer
                     </Typography>
                     <Typography variant="body2" style={{ textAlign: "justify" }}>
                        Glenn started as an apprentice software developer with QuarterMill Software, where he gained  
                        experience and transitioned to a full software developer role. He likes working with Javascript 
                        and Golang. In his free time, he enjoys exploring the St. Louis city restaurant &amp; craft beer scene, 
                        watching sports, and spending time with his wife &amp; cats.
                     </Typography>
                  </CardContent>
               </Card>
            </Box>
         </Box>
         <Box style={{padding:"60px 16px"}} >
            <Header variant="h5" align="center">
               Let's get in touch and discuss your tech needs!  
            </Header> 
            <Header variant="h6" align="center">
               Email our team at aaron@quartermillsoftware.com, call 314.397.5714, or <Link href='/#contact-form'>fill out our form</Link>.
            </Header> 
            <Header variant="h6" align="center">
               We look forward to speaking with you!
            </Header> 
         </Box>
         <Footer />
      </Box>
   )
}