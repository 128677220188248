import * as React from 'react';

import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Typography } from '@mui/material';

import Footer from '../../components/Footer'
import { GetIPCount, GetEmailCount, GetRouteCount, GetIPDateCount } from '../../api/usage';

   const PaperStyle = styled(Paper)(({ theme }) =>({
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(2),
   }));

   const StyledTable = styled(Table)(({ theme }) =>({
      borderLeft: '1px solid lightgrey',
      width: '200px',
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
   }));

   const StyledTableCell = styled(TableCell)({
      whiteSpace: 'nowrap',
      paddingLeft: '8px',
      paddingRight: '8px',
   });

   const ChartBox = styled(Box)({
      display: 'flex',
      // flexDirection: 'row',
   });

   const HeaderCell = styled(TableCell)({
      background: '#bbbbbb',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      paddingLeft: '8px',
      paddingRight: '8px',
   });

   const Title = styled(Typography)({
      fontWeight: 'bold',
      paddingLeft: '8px',
   });

export default function Usage() {

   React.useEffect(() => {
      loadUsage()
   }, []);

  const [emailCount, setEmailCount] = React.useState([])
  const [routeCount, setRouteCount] = React.useState([])
  const [ipCount, setIPCount] = React.useState([])
  const [ipDateCount, setIPDateCount] = React.useState([])
  const [ipTotal, setIPTotal] = React.useState(0)


   const loadUsage = async () => {
      try {
         let ipCount = await GetIPCount()
         let total = 0
         for (let item of ipCount) {
            total += item.Count
         }
         setIPTotal(total)
         setEmailCount(await GetEmailCount())
         setRouteCount(await GetRouteCount())
         setIPDateCount(await GetIPDateCount())
         setIPCount(ipCount)
      } catch (err) {
         setEmailCount([])
         setRouteCount([])
         setIPDateCount([])
         setIPCount([])
         setIPTotal(0)
      }
   }

   return (
      <PaperStyle>
         <Box>
            <Box>
            <Title variant='h5'>Routes</Title>
            <TableContainer>
               <StyledTable size='small' aria-label="route table">
                  <TableHead>
                     <TableRow>
                        <HeaderCell>
                           <Typography>Route</Typography>
                        </HeaderCell>
                        <HeaderCell>
                           <Typography>Count</Typography>
                        </HeaderCell>
                     </TableRow>
                  </TableHead>
                     <TableBody>
                     {routeCount.sort((a, b) => a.Count > b.Count ? -1:1).map((item, index) => (

                        <TableRow key={index}>
                           <StyledTableCell>
                              <Typography>{item.Route}</Typography>
                           </StyledTableCell>
                           <StyledTableCell >
                              <Typography>{item.Count}</Typography>
                           </StyledTableCell>
                        </TableRow>
                     ))}

                     </TableBody>
               </StyledTable>
            </TableContainer>
            </Box>
            <ChartBox>
            <Box>
            <Title variant='h5'>Hits: {ipTotal}</Title>
            <TableContainer style={{maxHeight: '370px'}}>
               <StyledTable stickyHeader size='small' aria-label="api table">
                  <TableHead>
                     <TableRow>
                        <HeaderCell>
                           <Typography>IP</Typography>
                        </HeaderCell>
                        <HeaderCell>
                           <Typography>Count</Typography>
                        </HeaderCell>
                     </TableRow>
                  </TableHead>
                     <TableBody>
                     {ipCount.sort((a, b) => a.Count > b.Count ? -1:1).map((item, index) => (
                        <TableRow key={index}>
                           <StyledTableCell>
                              <Typography>{item.IP}</Typography>
                           </StyledTableCell>
                           <StyledTableCell >
                              <Typography>{item.Count}</Typography>
                           </StyledTableCell>
                        </TableRow>
                     ))}
                     </TableBody>
               </StyledTable>
            </TableContainer>
            </Box>
            <Box>
            <Title variant='h5'style={{marginLeft: '8px'}}>Count by Date</Title>

            <TableContainer style={{maxHeight: '370px', marginLeft: '8px'}}>
               <StyledTable stickyHeader size='small' aria-label="ip table">
                  <TableHead>
                     <TableRow>
                        <HeaderCell>
                           <Typography>Date</Typography>
                        </HeaderCell>
                        <HeaderCell>
                           <Typography>Count</Typography>
                        </HeaderCell>
                     </TableRow>
                  </TableHead>
                     <TableBody>
                        {/* do not touch ip */}
                     {ipDateCount.sort((a, b) => a.Date > b.Date ? -1:1).map((item, index) => (
                        <TableRow key={index}>
                           <StyledTableCell>
                              <Typography>{item.Date}</Typography>
                           </StyledTableCell>
                           <StyledTableCell >
                              <Typography>{item.Count}</Typography>
                           </StyledTableCell>
                        </TableRow>
                     ))}
                     </TableBody>
               </StyledTable>
            </TableContainer>
            </Box>
            </ChartBox>
            <Box style={{marginLeft: '8px', marginTop: '8px'}}>
               <Typography>Total emails sent: {emailCount.Total} ({emailCount.Weekly} this week)</Typography>
            </Box>
         </Box>
         <Footer />
      </PaperStyle>
   )
}