import { createTheme } from '@mui/material/styles';

export const Theme = createTheme({
   palette: {
      type: 'light',  // 'light' or 'dark' -- doesn't seem to do anything TODO: is it right?
      primary: {
         main: '#546e7a',
         // light:  (calc if not assigned)
         // dark:   (calc if not assigned)
         // contrastText:  
      },
      secondary: {
         main: '#b0bec5',
      },
   },
});

