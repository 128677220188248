import * as React from 'react';

import { styled } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';



const Image = styled('img')(({ theme }) => ({
   width: "80px",
   marginRight: "5px",
   float: "center",
}));


export default function Footer() {
   const wideScreen = useMediaQuery('(min-width: 600px)')

   return (
      <Box>
         <Box style={{
            alignItems: "flex-start", display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap',
            flexDirection: wideScreen ? 'row' : 'column',
            backgroundColor: '#e1eff7', marginTop: '20px', padding: '20px', borderTop: '1px solid lightgray'
         }}>
            <Box style={{marginTop: '20px'}}>
               <Image src="images/qtrmill.png" alt="" />
               <Typography variant="h5" style={{ color: "black", align: "center" }} >QuarterMill Software, LLC</Typography>
            </Box>
            <Box style={{marginTop: '20px'}}>
               <Typography variant="body" style={{ color: "black" }} align="center">NAVIGATE</Typography>
               <br /> <br /> <Link href='/' variant="body" style={{ color: "green", align: "center" }}> &gt; Home</Link>
               <br /> <br /> <Link href='/about' variant="body" style={{ color: "green", align: "center" }}> &gt; About Us </Link>
            </Box>
            <Box style={{marginTop: '20px'}}>
               <Typography variant="body" style={{ color: "black", align: "center" }} >CONTACT US</Typography>
               <br /> <br /> <Typography variant="body" style={{ color: "green", align: "center" }} >QuarterMill Software </Typography>
               <br /> <Typography variant="body" style={{ color: "green", align: "center" }} >St. Louis, MO 63131 </Typography>
               <br /> <br /><Typography variant="body" style={{ color: "black", align: "center" }} >aaron@quartermillsoftware.com </Typography>
               <br /> <Typography variant="body" style={{ color: "black", align: "center" }} >314-397-5714</Typography>
            </Box>
         </Box>
         <Box style={{ alignItems: "center", display: 'flex', justifyContent: 'space-between', backgroundColor: '#546e7a', padding: '15px', borderTop: '1px solid lightgray' }}>
            <Box>
               <Typography variant="body" style={{ color: "white", align: "center" }} >Copyright 2016-2022</Typography>
               <br /> <Typography variant="body" style={{ color: "white", align: "center" }} >QuarterMill Software, LLC</Typography>
               <br /> <Typography variant="body" style={{ color: "white", align: "center" }} >All trademarks are property of their perspective owners.</Typography>
            </Box>
         </Box>

      </Box>
   )
}
